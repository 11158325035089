import './scss/main.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

gsap.from('.logo', { y: -100, duration: 0.75, ease: 'bounce(4)' });

(document.querySelectorAll('.button') as unknown as HTMLElement[]).forEach((button) => {
  button.addEventListener('click', () => {
    button.classList.add('clicked');
    setTimeout(() => {
      button.classList.remove('clicked');
    }, 100);
  });
});
